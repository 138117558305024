import Logo from "./Logo.webp";
import Headerimg from "./header-right.webp";
import AppStore from "./AppStore.webp";
import GooglePlay from "./GooglePlay.webp";
import featureIcon1 from "./feature1.png";
import featureIcon2 from "./feature2.png";
import featureIcon3 from "./feature3.png";
import featureIcon4 from "./feature4.png";
import featuresImg from "./features.webp";
import forcastImg from "./forcast.webp";
import appFigures from "./figures.webp";
import tick from "./Vector.png";
import downloadIcon from "./downloads.png";
import userIcon from "./user.png";
import reviewIcon from "./review.png";
import countriesIcon from "./countries.png";
import appStoreWhite from "./AppStoreWhite.webp";
import googlePlayWhite from "./GooglePlayWhite.webp";
import starIcon from "./star.png";
import starHalficon from "./star-half.png";
import quoteIcon from "./quote.png.png";
import reviewer1 from "./avatar-1.png.png";
import reviewer2 from "./avatar-2.png.png";
import reviewer3 from "./avatar-3.png.png";
import reviewPin from "./review__after.png";
import GooglePlayDark from "./GooglePlayDark.png";
import appStoreDark from "./AppStoreDark.png";
import fbIcon from "./fb.png";
import twitterIcon from "./twitter.png";
import youtubeIcon from "./youtube.png";
import pinterestIcon from "./pinterest.png";
import petrolPiceApp from "./Petrol Price.png";
import motorwayApp from "./Motorway Conditions Today.png";
import posingApp from "./Posing Camera.png";
import calorie from "./Food Calories.png";
import currencyApp from "./Currency Converter.png";
import headerimg1 from "./headerimg1.png";
import headerimg2 from "./headerimg2.png";
import headerimg3 from "./headerimg3.png";
import blogImage1 from "./Blogs/blog1.webp";
import blogImage2 from "./Blogs/blog2.webp";
import blogImage3 from "./Blogs/blog3.webp";
import blogImage4 from "./Blogs/blog4.webp";
import blogImage5 from "./Blogs/blog5.webp";
import blogImage6 from "./Blogs/blog6.webp";
import blogDetail from "./Blogs/blog-detail-img.webp";

const assetsImages = {
  Logo,
  Headerimg,
  AppStore,
  GooglePlay,
  featuresImg,
  featureIcon1,
  featureIcon2,
  featureIcon3,
  featureIcon4,
  forcastImg,
  appFigures,
  tick,
  downloadIcon,
  userIcon,
  countriesIcon,
  reviewIcon,
  appStoreWhite,
  googlePlayWhite,
  starIcon,
  starHalficon,
  quoteIcon,
  reviewer1,
  reviewer2,
  reviewer3,
  reviewPin,
  GooglePlayDark,
  appStoreDark,
  fbIcon,
  twitterIcon,
  youtubeIcon,
  pinterestIcon,
  petrolPiceApp,
  motorwayApp,
  posingApp,
  calorie,
  currencyApp,
  headerimg1,
  headerimg2,
  headerimg3,
  blogImage1,
  blogImage2,
  blogImage3,
  blogImage4,
  blogImage5,
  blogImage6,
  blogDetail,
};
export default assetsImages;
