import React, { useEffect } from "react";
import assetsImages from "../assets/assets";
import Slider from "react-slick";
import { useLocation } from "react-router-dom";
const Home = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      // Scroll to the top of the page if no hash is provided
      window.scrollTo(0, 0);
    }
  }, [location.hash]);
  var clientReviews = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    arrows: false,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <div className="homepage-main">
      {/* Homepage header starts */}
      <section className="header">
        <div className="homepage-header">
          <div className="container-fluid">
            <div className="container-xl py-4">
              <div className="row">
                <div className="col-md-12 col-lg-7 col-xl-6 px-xl-5 order-2 order-sm-2 order-md-2 order-lg-1">
                  <div className="header-title ">
                    <h2 className="mb-0">
                      <span className="color-primary">Moye Moye -</span>
                    </h2>
                    <h3>Making Weather Fun</h3>
                    <p className="pb-3 pb-xl-5 mb-xl-5">
                      It is not just a weather app — it's an experience!
                    </p>
                  </div>
                  <div className="d-flex Store-Links">
                    <div className="AppStore">
                      <a target="_blank" rel="noreferrer" href="#">
                        <img
                          src={assetsImages.AppStore}
                          className="img-fluid"
                          alt=""
                        ></img>
                      </a>
                    </div>
                    <div className="AppStore px-3">
                      <a
                        href="https://play.google.com/store/apps/details?id=com.atrule.bakwaasmausam"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={assetsImages.GooglePlay}
                          className="img-fluid"
                          alt=""
                        ></img>
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-lg-5 col-xl-6 order-1 order-sm-1 order-md-1 order-lg-2 text-center">
                  <div
                    id="carouselExampleIndicators"
                    className="carousel slide"
                    data-bs-ride="carousel"
                  >
                    <div className="carousel-indicators">
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="0"
                        className="active"
                        aria-current="true"
                        aria-label="Slide 1"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="1"
                        aria-label="Slide 2"
                      ></button>
                      <button
                        type="button"
                        data-bs-target="#carouselExampleIndicators"
                        data-bs-slide-to="2"
                        aria-label="Slide 3"
                      ></button>
                    </div>
                    <div className="carousel-inner">
                      <div className="carousel-item active">
                        <div className="header-img">
                          <img
                            src={assetsImages.headerimg1}
                            className="img-fluid "
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="header-img">
                          <img
                            src={assetsImages.headerimg2}
                            className="img-fluid "
                            alt=""
                          ></img>
                        </div>
                      </div>
                      <div className="carousel-item">
                        <div className="header-img">
                          <img
                            src={assetsImages.headerimg3}
                            className="img-fluid "
                            alt=""
                          ></img>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="home-after-bg"></div>
      {/* Homepage header ends */}
      {/* Homepage features starts */}
      <section className="features py-4 my-lg-5" id="feature">
        <div className="container-fluid">
          <div className="container-xl">
            <div className="features-header text-center">
              <h3>
                Game-Changing
                <span className="color-primary"> Features</span>
              </h3>
              <p>
                Moye Moye Weather combines accurate weather forecasts with
                entertaining and humorous content to brighten your day.
              </p>
            </div>
            <div className="features-bg py-4 ">
              <div className="container-xl">
                <div className="row">
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="feature-box text-end py-md-4">
                      <div className="icon py-2">
                        <img
                          className="img-fluid"
                          src={assetsImages.featureIcon1}
                          alt=""
                        ></img>
                      </div>
                      <h4 className="color-primary">
                        Humorous Weather Updates
                      </h4>
                      <p className="text-white fs-6">
                        Say goodbye to dull weather reports! Moye Moye Weather
                        delivers entertaining and amusing weather updates that
                        will bring a smile to your face, no matter what the
                        forecast
                      </p>
                    </div>
                    <div className="feature-box text-end py-md-4">
                      <div className="icon py-2">
                        <img
                          className="img-fluid"
                          src={assetsImages.featureIcon2}
                          alt=""
                        ></img>
                      </div>
                      <h4 className="color-primary">
                        Accurate Weather Forecasts
                      </h4>
                      <p className="text-white fs-6 ">
                        Receive up-to-date and precise weather forecasts for
                        your location, so you can plan your day with confidence
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4 d-none d-sm-none d-md-block position-relative">
                    <div className="features-main-img">
                      <img
                        className="img-fluid"
                        src={assetsImages.featuresImg}
                        alt=""
                      ></img>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-4 col-lg-4">
                    <div className="feature-box text-start py-md-4">
                      <div className="icon py-2">
                        <img
                          alt=""
                          className="img-fluid"
                          src={assetsImages.featureIcon3}
                        ></img>
                      </div>
                      <h4 className="color-primary">Daily Fun Facts</h4>
                      <p className="text-white fs-6 ">
                        Spice up your social media feed with hilarious weather
                        memes that you can easily share with friends and family,
                        spreading joy and laughter with every post
                      </p>
                    </div>
                    <div className="feature-box text-start py-md-4">
                      <div className="icon py-2">
                        <img
                          alt=""
                          className="img-fluid"
                          src={assetsImages.featureIcon4}
                        ></img>
                      </div>
                      <h4 className="color-primary">Continuous Updates</h4>
                      <p className="text-white fs-6">
                        Benefit from regular updates and improvements to ensure
                        Moye Moye Weather remains the go-to app for both
                        accurate weather forecasts and a good laugh
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* Homepage features starts */}
      {/* Homepage weather forcast */}
      <section className="forcast-section py-5 my-lg-5" id="forecast">
        <div className="container-xl">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 pe-lg-5">
              <div className="forcast-heading pt-xl-5">
                <h2>
                  Weather <span className="color-primary">Watch</span>
                </h2>
                <p className="text-white pb-3">
                  Stay one step ahead with Moye Moye Weather's complete
                  forecasting
                </p>
              </div>
              <div className="detail1 d-flex">
                <div className="pt-1 pe-3">
                  <img alt="" src={assetsImages.tick}></img>
                </div>
                <div>
                  <h5 className="color-primary">Hourly Forecaster:</h5>
                  <p className="text-white">
                    Don't Miss a Beat: Hourly Forecasts Keep You in the Know!{" "}
                  </p>
                </div>
              </div>
              <div className="detail1 d-flex">
                <div className="pt-1 pe-3">
                  <img alt="" src={assetsImages.tick}></img>
                </div>
                <div>
                  <h5 className="color-primary">
                    City-wise Forecast Around the Globe:
                  </h5>
                  <p className="text-white">
                    From Tokyo to Timbuktu: Detailed Forecasts for Cities
                    Worldwide{" "}
                  </p>
                </div>
              </div>
              <div className="detail1 d-flex">
                <div className="pt-1 pe-3">
                  <img alt="" src={assetsImages.tick}></img>
                </div>
                <div>
                  <h5 className="color-primary">Daily Forecast:</h5>
                  <p className="text-white">
                    Daily Decipher: Decode Tomorrow's Weather Today{" "}
                  </p>
                </div>
              </div>
              <div className="d-flex Store-Links pt-5">
                <div className="AppStore">
                  <a target="_blank" rel="noreferrer" href="#">
                    <img
                      src={assetsImages.AppStore}
                      className="img-fluid"
                      alt=""
                    ></img>
                  </a>
                </div>
                <div className="AppStore px-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atrule.bakwaasmausam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src={assetsImages.GooglePlay}
                      className="img-fluid"
                      alt=""
                    ></img>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="text-center py-5 py-sm-5 py-md-5 py-lg-0">
                <img
                  alt=""
                  className="img-fluid"
                  src={assetsImages.forcastImg}
                ></img>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="weather-figures-section py-lg-5 py-sm-3">
        <div className="container-xl py-lg-5">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6">
              <div className="text-center py-5 py-sm-5 py-md-5 py-lg-0">
                <img
                  alt=""
                  className="img-fluid"
                  src={assetsImages.appFigures}
                ></img>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 px-xl-5">
              <div className="forcast-heading">
                <h2>
                  Engagement <span className="color-primary"> Metrics</span>
                </h2>
                <p className="text-white py-3">
                  Join our growing community of weather enthusiasts and discover
                  why Moye Moye Weather is the go-to choice of millions
                  worldwide
                </p>
              </div>
              <div className="py-sm-4 py-xl-5 d-flex ">
                <div className="figure-box d-flex px-4 py-2">
                  <div className="icon">
                    <img
                      className="img-fluid"
                      src={assetsImages.downloadIcon}
                      alt=""
                    ></img>
                  </div>
                  <div className="data ps-4">
                    <h5 className="number color-primary mb-0">20k+</h5>
                    <p className="text-white mb-0">Download</p>
                  </div>
                </div>
                <div className="figure-box d-flex px-4 py-2 ms-xl-5 ms-lg-3 ms-md-5 ms-sm-5">
                  <div className="icon">
                    <img
                      className="img-fluid"
                      src={assetsImages.reviewIcon}
                      alt=""
                    ></img>
                  </div>
                  <div className="data ps-4">
                    <h5 className="number color-primary mb-0">3k+</h5>
                    <p className="text-white mb-0">Reviews</p>
                  </div>
                </div>
              </div>
              <div className=" d-flex">
                <div className="figure-box d-flex px-4 py-2">
                  <div className="icon">
                    <img
                      className="img-fluid"
                      src={assetsImages.userIcon}
                      alt=""
                    ></img>
                  </div>
                  <div className="data ps-4">
                    <h5 className="number color-primary mb-0">10k+</h5>
                    <p className="text-white mb-0">Followers</p>
                  </div>
                </div>
                <div className="figure-box d-flex px-4 py-2 ms-xl-5 ms-lg-3 ms-md-5 ms-sm-5">
                  <div className="icon">
                    <img
                      className="img-fluid"
                      src={assetsImages.countriesIcon}
                      alt=""
                    ></img>
                  </div>
                  <div className="data ps-4">
                    <h5 className="number color-primary mb-0">200+</h5>
                    <p className="text-white mb-0">Countries</p>
                  </div>
                </div>
              </div>
              <div className="d-flex Store-Links py-5">
                <div className="AppStore">
                  <a>
                    <img
                      alt=""
                      src={assetsImages.appStoreWhite}
                      className="img-fluid"
                    ></img>
                  </a>
                </div>
                <div className="AppStore px-3">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atrule.bakwaasmausam"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      alt=""
                      src={assetsImages.googlePlayWhite}
                      className="img-fluid"
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="testimonials py-5">
        <div className="container-xl">
          <div className="forcast-heading text-center">
            <h2>
              User <span className="color-primary"> Actions </span>
            </h2>
            <p className="text-white pb-3">
              Get ready to chuckle your way through Moye Moye Weather's user
              actions: where the forecast is always sunny with a chance of
              laughter!
            </p>
          </div>
          <div className="reviews">
            <div className="container px-lg-5 mx-lg-5">
              <Slider {...clientReviews} className="align-items-center py-4">
                <div className="swiper-slide px-2">
                  <div className="review-box">
                    <div className="content-box">
                      <div className="quote float-end">
                        <img src={assetsImages.quoteIcon} alt=""></img>
                      </div>
                      <div className="stars d-flex pb-lg-4">
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starHalficon} alt=""></img>
                      </div>

                      <h5 className="fw-bold color-primary">
                        Excellent service & support!!
                      </h5>
                      <p>
                        This app is a game-changer! Now I can check accurate
                        weather forecast with a touch of humor every day.
                      </p>
                    </div>
                    <div className="reviewer-box py-3 px-4 position-relative">
                      <div className="pin">
                        <img src={assetsImages.reviewPin} alt=""></img>
                      </div>
                      <div className="d-flex">
                        <div className="user-img">
                          <img
                            className="img-fluid"
                            src={assetsImages.reviewer1}
                            alt=""
                          ></img>
                        </div>
                        <div className="name p-3">
                          <h6 className="text-white">Junaid Hasan</h6>
                          <p>App User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slider px-2">
                  <div className="review-box">
                    <div className="content-box">
                      <div className="quote float-end">
                        <img src={assetsImages.quoteIcon} alt=""></img>
                      </div>
                      <div className="stars d-flex pb-lg-4">
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starHalficon} alt=""></img>
                      </div>

                      <h5 className="fw-bold color-primary">
                        Nice work! Keep it up
                      </h5>
                      <p>
                        Moye Moye Weather has become my go-to app for weather
                        updates. The fun forecasts never fail to brighten my
                        day. Love it!
                      </p>
                    </div>
                    <div className="reviewer-box py-3 px-4 position-relative">
                      <div className="pin">
                        <img src={assetsImages.reviewPin} alt=""></img>
                      </div>
                      <div className="d-flex">
                        <div className="user-img">
                          <img
                            className="img-fluid"
                            src={assetsImages.reviewer2}
                            alt=""
                          ></img>
                        </div>
                        <div className="name p-3">
                          <h6 className="text-white">Hira Khan</h6>
                          <p>App User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slide px-2">
                  <div className="review-box">
                    <div className="content-box">
                      <div className="quote float-end">
                        <img src={assetsImages.quoteIcon} alt=""></img>
                      </div>
                      <div className="stars d-flex pb-lg-4">
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starHalficon} alt=""></img>
                      </div>

                      <h5 className="fw-bold color-primary">Great app!</h5>
                      <p>
                        Finally, a weather app that doesn't take itself too
                        seriously. :D It’s fun touch while maintaining the
                        accuracy never fails me.
                      </p>
                    </div>
                    <div className="reviewer-box py-3 px-4 position-relative">
                      <div className="pin">
                        <img src={assetsImages.reviewPin} alt=""></img>
                      </div>
                      <div className="d-flex">
                        <div className="user-img">
                          <img
                            className="img-fluid"
                            src={assetsImages.reviewer3}
                            alt=""
                          ></img>
                        </div>
                        <div className="name p-3">
                          <h6 className="text-white">Ahmed shah</h6>
                          <p>App User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="swiper-slider px-2">
                  <div className="review-box">
                    <div className="content-box">
                      <div className="quote float-end">
                        <img src={assetsImages.quoteIcon} alt=""></img>
                      </div>
                      <div className="stars d-flex pb-lg-4">
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starIcon} alt=""></img>
                        <img src={assetsImages.starHalficon} alt=""></img>
                      </div>

                      <h5 className="fw-bold color-primary">
                        Weather with a Twist!
                      </h5>
                      <p>
                        This app is a gem! I open this app first thing in the
                        morning. It never fails to give me a great start of the
                        day. Highly recommended!
                      </p>
                    </div>
                    <div className="reviewer-box py-3 px-4 position-relative">
                      <div className="pin">
                        <img src={assetsImages.reviewPin} alt=""></img>
                      </div>
                      <div className="d-flex">
                        <div className="user-img">
                          <img
                            className="img-fluid"
                            src={assetsImages.reviewer2}
                            alt=""
                          ></img>
                        </div>
                        <div className="name p-3">
                          <h6 className="text-white">Asifa Ali</h6>
                          <p>App User</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
