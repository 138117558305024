import React from "react";
import assetsImages from "../assets/assets";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <footer className="footer-main pt-5">
        <div className="container-fluid">
          <div className="container-xl">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-3">
                <div className="footer-logo text-center py-3">
                  <Link to="/">
                    <img src={assetsImages.Logo} className="img-fluid"></img>
                  </Link>
                </div>
                <div className="d-flex Store-Links px-md-4">
                  <div className="AppStore">
                    <a target="_blank" href="#">
                      <img
                        src={assetsImages.appStoreDark}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                  <div className="AppStore px-3">
                    <a
                      href="https://play.google.com/store/apps/details?id=com.atrule.bakwaasmausam"
                      target="_blank"
                    >
                      <img
                        src={assetsImages.GooglePlayDark}
                        className="img-fluid"
                        alt=""
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <h5 className="text-white pt-5 pt-sm-5 pt-md-5 pt-lg-0 pb-3">
                  Contact us
                </h5>
                <div className="links">
                  <a
                    className="text-white  fs-6"
                    href="mailto:hello@atrule.com"
                  >
                    hello@atrule.com
                  </a>
                  <p className="text-white fs-6 pe-4 pt-2">
                    5900 Balcones Drive Suite 100, Austin, TX 78731, United
                    States of America.
                  </p>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <h5 className="text-white pb-3">Company</h5>
                <a
                  className="text-white d-block text-decoration-none pb-2 fs-6"
                  href="https://atrule.com/about/"
                  target="_blank"
                >
                  About Atrule
                </a>
                <a
                  className="text-white d-block text-decoration-none pb-2 fs-6"
                  href="https://atrule.com/services/"
                  target="_blank"
                >
                  Services
                </a>
                <a
                  className="text-white d-block text-decoration-none pb-2 fs-6"
                  href="https://atrule.com/about/"
                  target="_blank"
                >
                  About Atrule
                </a>
                <a
                  className="text-white d-block text-decoration-none pb-2 fs-6"
                  href="https://atrule.com/contactus/"
                  target="_blank"
                >
                  Contact Us
                </a>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-3">
                <h5 className="text-white pb-3">Social Links</h5>
                <div className="links pb-3 ">
                  <a
                    className="pe-3"
                    target="_blank"
                    href="https://www.facebook.com/atruletech"
                  >
                    <img src={assetsImages.fbIcon} className="img-fluid"></img>
                  </a>
                  <a
                    className="pe-3"
                    target="_blank"
                    href="https://twitter.com/Atruletech"
                  >
                    <img
                      src={assetsImages.twitterIcon}
                      className="img-fluid"
                    ></img>
                  </a>
                  <a
                    className="pe-3"
                    target="_blank"
                    href="https://www.youtube.com/@atruletechnologies"
                  >
                    <img
                      src={assetsImages.youtubeIcon}
                      className="img-fluid"
                    ></img>
                  </a>
                  <a
                    className="pe-3"
                    target="_blank"
                    href="https://www.pinterest.com/atruletech/"
                  >
                    <img
                      src={assetsImages.pinterestIcon}
                      className="img-fluid"
                    ></img>
                  </a>
                </div>
                <hr className="text-white w-50"></hr>
                <a
                  className="text-white d-block text-decoration-none pb-2 fs-6"
                  href="https://atrule.com/privacy/"
                  target="_blank"
                >
                  Privacy and Policy
                </a>
              </div>
            </div>
            <div className="popular-Apps d-flex-block d-sm-flex pt-4 justify-content-center">
              <div>
                <h6 className="text-white pe-2 pt-md-3 ">Our Popular Apps:</h6>
              </div>
              <div className="d-flex">
                <div className="icons px-2">
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.atrule.pakistanpetrolpricetoday"
                  >
                    <img
                      className="img-fluid"
                      src={assetsImages.petrolPiceApp}
                    ></img>
                  </a>
                </div>
                <div className="icons px-2">
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.atrule.weatherhighway"
                  >
                    <img
                      className="img-fluid"
                      src={assetsImages.motorwayApp}
                    ></img>
                  </a>
                </div>
                <div className="icons px-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atrule.calorieapp"
                    target="_blank"
                  >
                    <img className="img-fluid" src={assetsImages.calorie}></img>
                  </a>
                </div>
                <div className="icons px-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atrule.posingapp"
                    target="_blank"
                  >
                    <img
                      className="img-fluid"
                      src={assetsImages.posingApp}
                    ></img>
                  </a>
                </div>
                <div className="icons px-2">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.atrule.currencyconverter"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="img-fluid"
                      src={assetsImages.currencyApp}
                    ></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="py-3 text-center text-white footer-lower">
        ​2024 © Atrule. All rights reserved
      </div>
    </div>
  );
};

export default Footer;
