import React from "react";

const Page404 = () => {
  return (
    <div>
      <h2 className="p-5 text-center">Page Not Found</h2>
    </div>
  );
};

export default Page404;
