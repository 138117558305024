import React from "react";
import { Link } from "react-router-dom";
import assetsImages from "../assets/assets";
const BlogDetails = () => {
  const recentBlogData = [
    {
      id: 1,
      image: assetsImages.blogImage1,
      name: "Blog Name 1",
      date: "March 13, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
    {
      id: 2,
      image: assetsImages.blogImage2,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
    {
      id: 3,
      image: assetsImages.blogImage3,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
    {
      id: 4,
      image: assetsImages.blogImage4,
      name: "Blog Name 2",
      date: "March 14, 2024",
      description:
        "Lorem ipsum dolor sit amet, consectetur adip iscing elit. Molestie blandit nibh.",
    },
  ];
  return (
    <div>
      <div className="container-xl py-5">
        <div className="row">
          <div className="xol-12 col-sm-12 col-md-12 col-lg-8">
            <div className="blog-detail">
              <p className="fw-bold color-primary py-2 mb-0">March 13, 2024</p>
              <h1 className="pt-lg-4">
                Lorem ipsum dolor sit amet, cons ectetur adipiscing elit.
              </h1>
              <img
                className="img-fluid w-100 pb-3"
                src={assetsImages.blogDetail}
              ></img>
              <p>
                A grid system is a design tool used to arrange content on a
                webpage. It is a series of vertical and horizontal lines that
                create a matrix of intersecting points, which can be used to
                align and organize page elements. Grid systems are used to
                create a consistent look and feel across a website, and can help
                to make the layout more visually appealing and easier to
                navigate.
              </p>
              <p>
                If you’ve been to New York City and have walked the streets, it
                is easy to figure out how to get from one place to another
                because of the grid system that the city is built on. Just as
                the predictability of a city grid helps locals and tourists get
                around easily, so do webpage grids provide a structure that
                guides users and designers alike. Because of their consistent
                reference point, grids improve page readability and scannability
                and allow people to quickly get where they need to go.
              </p>
              <img
                src={assetsImages.blogImage2}
                className="img-fluid w-100"
              ></img>
              <p>
                Modular grid extends the column grid further by adding rows to
                it. This intersection of columns and rows make up modules to
                which elements and content are aligned. Modular grids are great
                for ecommerce and listing pages, as rows are repeatable to
                accommodate browsing.
              </p>
              <p>
                Hierarchical grid: Content is organized by importance using
                columns, rows, and modules. The most important elements and
                pieces of content take up the biggest pieces of the grid.
              </p>
            </div>
          </div>
          <div className="xol-12 col-sm-12 col-md-12 col-lg-4">
            <div className="container">
              <h4 className="py-2">Recent blog posts</h4>
              <div className="row">
                {recentBlogData.map((blog) => (
                  <div
                    className="col-12 col-sm-12 col-md-12 blog-post py-2"
                    key={blog.id}
                  >
                    <img
                      className="img-fluid shadow rounded w-100"
                      src={blog.image}
                      alt={blog.name}
                    />
                    <p className="fw-bold color-primary py-2 mb-0">
                      {blog.date}
                    </p>
                    <h4>{blog.name}</h4>

                    <p className="desc">{blog.description}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
